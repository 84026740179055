import { createSlice } from '@reduxjs/toolkit';
import { shipBox } from './shipBox.actions';

const initialState = {
  status: '',
  message: '',
  packagesNumber: null,
  missingFruitsAndVegetables: [],
  structuredError: null
};

const shipBoxSlice = createSlice({
  name: 'shipBox',
  initialState,
  reducers: {
    resetShipBox: (state) => {
      state.status = '';
      state.message = '';
      state.packagesNumber = null;
      state.missingFruitsAndVegetables = [];
      state.structuredError = null;
    },
    setMissingFruitsAndVegetables: (state, action) => {
      state.missingFruitsAndVegetables = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(shipBox.fulfilled, (state, { payload }) => {
        state.status = payload.status;
        state.packagesNumber = payload.data.packagesNumber;
        state.missingFruitsAndVegetables =
          payload.data.missingFruitsAndVegetables;
        state.structuredError = null;
      })
      .addCase(shipBox.rejected, (state, { payload }) => {
        state.message = payload.data.message;
        state.status = payload.status;
        state.packagesNumber = payload.data.packagesNumber;
        state.missingFruitsAndVegetables =
          payload.data.missingFruitsAndVegetables;
        if (payload?.data) {
          state.structuredError = payload?.data;
        }
      });
  }
});

export const { resetShipBox, setMissingFruitsAndVegetables } =
  shipBoxSlice.actions;

export default shipBoxSlice.reducer;
