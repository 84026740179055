import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import barcodeImg from '@/assets/barcode_scanner.png';
import { Modal, ScanContainer } from '@/components';
import { toggleModal } from '@/redux/modal/modal.slice';
import validCheck from '@/assets/valid_check.png';
import crossErrorIcon from '@/assets/cross_error_icon.png';
import { resetIsScanValid } from '@/redux/barcode/barcode.slice';
import { resetShipBox } from '@/redux/shipBox/shipBox.slice';
import MissingProductsIcon from '@/assets/missing_product.png';
import { tryTranslateError } from '@/helpers/helpers';
import { validateScan } from '@/redux/barcode/barcode.actions';

function ShipBox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    status,
    message,
    packagesNumber,
    missingFruitsAndVegetables,
    structuredError
  } = useSelector((state) => state.shipBox);
  const { isScanValid, value } = useSelector((state) => state.barcode);
  const [boxState, setBoxState] = useState(null);

  useEffect(() => {
    if (status === 200) {
      setBoxState('success');
    } else if (status === 400 && structuredError) {
      setBoxState('structured_error');
    } else if (status === 404) {
      setBoxState('not_found');
    } else if (status === 409) {
      setBoxState('already_shipped');
    } else if (status === 423) {
      setBoxState('previous_box_missing');
    } else if (status === 425) {
      setBoxState('grocery_not_done');
    }
  }, [status, structuredError]);

  useEffect(() => {
    if (isScanValid === false) {
      dispatch(toggleModal(true));
      setTimeout(() => {
        dispatch(toggleModal(false));
        dispatch(resetIsScanValid());
        dispatch(resetShipBox());
      }, 3000);
    } else {
      setTimeout(() => {
        dispatch(resetIsScanValid());
      }, 3000);
    }
  }, [isScanValid, missingFruitsAndVegetables]);

  const resolveModalText = () => {
    if (boxState === 'structured_error') {
      return tryTranslateError(structuredError);
    }
    if (boxState === 'already_shipped') {
      return t('views.ship_box.already_shipped');
    }
    if (boxState === 'not_found') {
      return t('views.ship_box.unkown_box');
    }
    if (boxState === 'previous_box_missing') {
      return t('views.ship_box.previous_box_missing');
    }
    if (boxState === 'grocery_not_done') {
      return t('views.ship_box.grocery_not_done');
    }

    return null;
  };

  const modalContent = () => (
    <div className="ship-box-modal-wrapper">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <img
          src={boxState === 'already_shipped' ? validCheck : crossErrorIcon}
          alt="valid_check"
        />
        <div dangerouslySetInnerHTML={{ __html: resolveModalText() }} />
      </div>
      <span style={{ textAlign: 'center', marginTop: '1em' }}>
        {boxState !== 'grocery_not_done' ? message : ''}
      </span>
      {packagesNumber?.length && packagesNumber?.length !== 0 && (
        <span style={{ textAlign: 'center', marginTop: '1em' }}>
          {t('views.ship_box.grocery_not_done_message')}{' '}
          {packagesNumber?.map((packageName) => (
            <div>{packageName}</div>
          ))}
        </span>
      )}
    </div>
  );

  const content = () => {
    if (isScanValid === undefined || isScanValid === false) {
      return (
        <img
          src={barcodeImg}
          alt="barcode"
          style={{ position: 'absolute', top: '8em' }}
        />
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <img src={validCheck} alt="valid_check" />
        <span>{t('views.ship_box.box_shipped')}</span>
        <span style={{ color: '#EC5500', fontWeight: '700', fontSize: '1em' }}>
          BB-{value}
        </span>
        {missingFruitsAndVegetables?.length > 0 && (
          <div className="ship-box-missing-products-container">
            <div className="ship-box-missing-products-container__text">
              <span>{t('views.return_box.missing_products_declared')}</span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img src={MissingProductsIcon} alt="missing product" />
              <ul>
                {missingFruitsAndVegetables?.map((product, index) => (
                  <li>{product}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <ScanContainer type="ship_box">
      <div className="u-page-center">
        <Modal>{modalContent()}</Modal>
        <br />
        {content()}
        <span className="u-scan" style={{ top: '26em', position: 'absolute' }}>
          {t('views.ship_box.scan')}
        </span>
      </div>
    </ScanContainer>
  );
}

export default ShipBox;
